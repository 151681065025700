@use "@delta-defense/delta-utils/styleguide/mixins" as util-mixins;
@import "../../variables.scss";

@mixin is-cabelas-shield {
  .fascia-cabelas &, &.fascia-cabelas {
    @content;
  }
}

.additional-info {
  color: $mono-3;
  padding-top: 1.56rem;

  .headline {
    font-weight: 700;
    font-size: 18px;
  }

  .headline--no-account {
    font-size: 0.9375rem;
    font-weight: 600;
  }

  .download-headline {
    font-size: 16px;
    color: #fff;
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .link {
    --additional-info-link-color: #cdb88a;
    font-size: 0.9375rem;
    font-weight: 600;
    text-decoration: none;

    @include is-cabelas-shield {
      --main-background-color: var(--color-cs-blue);
    }

    color: var(--additional-info-link-color);

    &:hover {
      color: $link-dark;
    }
  }
}

.get-mobile-app {
  color: $mono-3;
  font-size: 0.875rem;
  line-height: 1.6;

  &__horizontal-line {
    margin-top: 2.81rem;
    margin-bottom: 2.81rem;
    border: 0.05rem solid $mono-5;
    width: 100%;
  }

  &__h3 {
    margin-top: 2.81rem;
    font-size: 1.125rem;
    margin-bottom: 1.56rem;
  }

  &__p {
    font-size: 0.875rem;
  }

  .app-store-icons {
    max-width: 130px;
    border: 1px solid $mono-2;
    border-radius: 5px;
  }
}

.email-change {
  padding: 15px;
  color: $mono-3;
  background-color: #232324;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-top: 2.8125rem;
  border-radius: 5px;

  &__link {
    font-size: 0.875rem;
    font-weight: 600;
    color: $link;
    text-decoration: none;

    &:hover {
      color: $link-dark;
    }
  }
}
