@use "@delta-defense/delta-utils/styleguide/mixins" as util-mixins;

@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/utilities/spacing";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/media";
@import '../node_modules/@delta-defense/b2c-shared-react/components/toolTip/styles.css';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

@import "./variables.scss";
@include util-mixins.font-faces;

:root {
  @include util-mixins.color-variables;
  @include util-mixins.font-variables;
}

@mixin is-cabelas-shield {
  .fascia-cabelas &, &.fascia-cabelas {
    @content;
  }
}

@mixin is-cabelas-shield-styles {
  .cabelas-style {
    @content;
  }
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  background-color: var(--color-mono);
  font-family: $font-body;

  &:before {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-index: -1;
  }
}

h1 {
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.main {
  padding-bottom: 65px;
  font-family: $font-body;
  --main-background-color: var(--color-mono);

  @include is-cabelas-shield {
    --main-background-color: var(--color-cs-blue);
  }

  background-color: var(--main-background-color);
}

.fixed-col {
  max-width: pxToRem(295);

  @media (min-width: $xs - 1px) {
    max-width: pxToRem(370);
  }
}

.fixed-col-uscca-style {
  @extend .fixed-col
}

.fixed-col-cabelas-style {
  display: flex;
  justify-content: center;
}

.card {
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 3px solid $border;
}

.uscca-logo {
  g {
    fill: white;
  }

  @media (max-width: $sm - 1px) {
    width: 80px;
    height: 84px;
  }
}

.text-center {
  text-align: center;
}

.btn {
  display: inline-block;
  line-height: 1.1;
  border: 1px solid transparent;
  font-family: $font-body;
  font-size: 1rem;
  font-weight: $bold;
  padding: pxToRem(11) pxToRem(28) pxToRem(12);
  text-align: center;
  transition: color 0.2s ease, background-color 0.2s ease,
  border-color 0.2s ease;
  user-select: none;
  vertical-align: middle;
  border-radius: 5px;
  margin: 0 0;
  letter-spacing: 0.05em;
  min-width: pxToRem(150);
  letter-spacing: 0;
  width: 100%;
  cursor: pointer;

  &-link {
    margin: 0;
    &:hover {
      text-decoration: underline;
    }
  }

  &-lg {
    font-size: 1.5rem;
    padding: pxToRem(15) pxToRem(32) pxToRem(17);
    min-width: pxToRem(250);
    max-width: pxToRem(350);

    @media (min-width: $sm) {
      min-width: pxToRem(300);
      max-width: pxToRem(450);
    }
  }

  &-sm {
    font-size: 1rem;
    font-weight: $bold;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    padding: pxToRem(6) pxToRem(20) pxToRem(7);
    min-width: 0;
    max-width: pxToRem(300);
  }

  &-primary {
    background: $prime;
    color: $white;
    border: 1px solid transparent;
    &:active {
      background: $prime !important;
      color: $mono !important;
      border: 1px solid transparent !important;
    }
    &:hover {
      background: #2f5ca8;
    }
  }

  &-alt {
    background: $mono;
    color: $white !important;
    &:hover {
      color: $prime !important;
    }
  }

  &-gold {
    background: $prime-5;
    color: $mono !important;
    &:hover {
      background: darken($prime-5, 10%) !important;
    }
  }


  &-plat {
    background: $mono-4;
    color: $white !important;
    &:hover {
      background: $mono-5 !important;
    }
  }

  &-elite {
    @extend .btn-primary;
  }

  &-default {
    background: transparent;
    color: $mono !important;
    border-color: $mono;
    &:hover {
      background-color: $prime !important;
    }
  }

  &-default-dark-bg {
    background: transparent;
    color: $white !important;
    border-color: $white;
    &:hover {
      background-color: $white !important;
      color: $mono !important;
    }
  }

  &-success {
    background: $success;
    color: $white !important;
    &:hover {
      background: $success-hover;
    }
  }

  &-warning {
    background: $warning;
    color: $white !important;
    &:hover {
      background: $warning-hover;
    }
  }

  &-disabled,
  &:disabled {
    background: $mono-3 !important;
    color: $white !important;
    pointer-events: none !important;
    border-color: transparent !important;
  }
}

.font-body {
  font-family: $font-body;
}
.font-title {
  font-family: $font-title;
}

.disabled {
  @extend .btn-disabled;
}

.hidden-frame {
  display: none;
  height: 0;
  width: 0;
  overflow: hidden;
}

// Prevents double clicking - needed due to third party controls used for this input
#signInRememberMeCheckBox {
  pointer-events: none;
}

.form-field__validate--error {
  font-size: 0.875rem;
}

@include is-cabelas-shield-styles {
  background-color: var(--color-white);
  max-width: 450px;
  width: 100%;
  padding: 30px 20px;
  margin: -16px 0 0;
  text-align: center;

  @media (min-width: 360px) {
    padding: 30px 40px
  }

  @media (min-width: $xs) {
    padding: 30px 50px
  }

  #signInSignInButton {
    background-color: var(--color-cs-yellow);
    border: 1px solid var(--color-cs-blue);
    color: var(--color-cs-blue);
    margin: 0 0 48px;
    &:hover {
      background: darken(#F6BE00, 5%) !important;
    }
  }

  .form-field {
    color: var(--color-cs-blue);
    &__input {
      border: 1px solid var(--color-mono);
      border-radius: 0;
    }
  }

  .auth-title {
    color: var(--color-cs-blue);
    font-size: pxToRem(30);
    font-family: var(--font-family-tactic-sans);
  }

  #resetPasswordVerificationButton {
    background-color: var(--color-cs-yellow);
    border: 1px solid var(--color-cs-blue);
    color: var(--color-cs-blue);
    &:hover {
      background: darken(#F6BE00, 5%) !important;
    }
  }

  .additional-info,
    #signUpTodayButton {
      color: var(--color-cs-blue);
    }
  
  .remember-label {
    color: var(--color-cs-blue);
    font-family: var(--font-family-helvetica-neue-lt-std);
    font-weight: 200;
  }

  .signInLinks {
    color: var(--color-cs-blue);
    &:hover {
      color: var(--color-cs-blue);
    }
  }

  .sign-in-links {
    display: flex;
    align-items: end;
    a {
      font-family: var(--font-family-helvetica-neue-lt-std);
      color: var(--color-cs-blue);
      font-weight: 200;
      &:hover {
        color: var(--color-cs-blue);
      }
    }
  }

  .remember-label {
    padding: 0 0 0 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .remember-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--color-white);
    border: 1px solid var(--color-mono);
  }

  .remember-label:hover input ~ .checkmark {
    background-color: #E2DFD8;
  }

  .remember-label input:checked ~ .checkmark {
    background-color: var(--color-white);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .remember-label input:checked ~ .checkmark:after {
    display: block;
    border-color: var(--color-cs-blue);
    left: 7px;
    top: 3px;
    width: 10px;
    height: 17px;
    border-width: 0 4px 4px 0;
  }

  .remember-label .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--color-white);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .email-change {
    background-color: var(--color-white);
    border: 1px solid var(--color-cs-blue);

    p,
    a {
      color: #FF00FF;
      text-align: left;
    }
  }
}

